<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#427D2D" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-start pa-5>
          <v-flex xs12>
            <span class="itemHeading1">Add Hospital</span>
          </v-flex>
          <!-- <v-flex xs12 pt-2>
            <span class="heading1">Basic Information</span>
          </v-flex> -->
          <v-flex xs12>
            <!-- BASIC INFO -->
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>Name</span>
                <v-text-field outlined v-model="name" dense hide-details>
                </v-text-field>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-sm-2
                pl-md-2
                class="subheading"
              >
                <span>Latitude</span>
                <v-text-field
                  outlined
                  v-model="lat"
                  dense
                  hide-details
                  type="number"
                  class="inputPrice"
                >
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>Longitude</span>
                <v-text-field outlined v-model="lon" dense hide-details>
                </v-text-field>
              </v-flex>
              <v-flex  pl-lg-2
              pl-sm-2
              pl-md-2 xs12 sm6 md6 lg6 class="subheading">
                <span>State</span>
                <v-text-field outlined v-model="state" dense hide-details>
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
           


              <v-flex
                xs12
                sm6
                md6
                lg6
               
                class="subheading"
              >
                <span>District</span>
                <v-text-field outlined v-model="district" dense hide-details>  </v-text-field>
                  <!-- <v-select
                  outlined
                  hide-details
                  dense clearable
                  :items="districts"
                  v-model="district"
                  placeholder="Filter By District"
                  style="font-family: sofiaProRegular;"
                >
                </v-select>   -->

               
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-sm-2
                pl-md-2
                class="subheading"
              >
                <span>Address</span>
                <v-text-field outlined v-model="address" dense hide-details>
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>MobileNo</span>
                <v-text-field
                  outlined
                  v-model="mobileno"
                  dense
                  hide-details
                  type="number"
                  class="inputPrice"
                >
                </v-text-field>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-sm-2
                pl-md-2
                class="subheading"
              >
                <span>Category</span>
                <v-select
                  outlined
                  v-model="category"
                  dense
                  hide-details
                  :items="categorylist"
                >
                </v-select>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>Password</span>
                <v-text-field outlined v-model="password" dense hide-details>
                </v-text-field>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-sm-2
                pl-md-2
                class="subheading"
              >
                <span>Confirm Password</span>
                <v-text-field outlined v-model="cpassword" dense hide-details>
                </v-text-field>
              </v-flex>
            </v-layout>




            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm12 md12 lg12 class="subheading">
                <span>Notes</span>
                <v-text-field outlined v-model="notes" dense hide-details>
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-end pt-2>
              <v-flex xs12 sm6 md6 lg6 text-end>
                <v-btn depressed color="#427D2D" @click="handlesubmit()">
                  <span style="color:white">Submit</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
    <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      name: "",
      lat: "",
      lon: "",
      district: "",
      state: "",
      address: "",
      mobileno: "",
      category: "",
      password:"",
      cpassword:"",
      categorylist: ["Government", "Private", "Not for profit"],
      notes: "",
      districts: [
        "All",
        "Angul",
        "Boudh",
        "Balangir",
        "Bargarh",
        "Balasore",
        "Bhadrak",
        "Cuttack",
        "Deogarh",
        "Dhenkanal",
        "Ganjam",
        "Gajapati",
        "Jharsuguda",
        "Jajpur",
        "Jagatsinghapur",
        "Khordha",
        "Keonjhar",
        "Kalahandi",
        "Kandhamal",
        "Koraput",
        "Kendrapara",
        "Malkangiri",
        "Mayurbhanj",
        "Nabarangpur",
        "Nuapada",
        "Nayagarh",
        "Puri",
        "Rayagada",
        "Sambalpur",
        "Subarnapur",
        "Sundargarh",
      ],
    };
    
  },
  methods: {
    handlesubmit() {
      const phoneRegex = /^[0-9]{10}$/;
      if (!this.name) {
        this.msg = "Please Provide Name.";
        this.showSnackBar = true;
        return;
      }
      if (!this.lat) {
        this.msg = "Please Provide Latitude.";
        this.showSnackBar = true;
        return;
      }
      if (!this.lon) {
        this.msg = "Please Provide Longitude.";
        this.showSnackBar = true;
        return;
      }
      if (!this.district) {
        this.msg = "Please Provide District.";
        this.showSnackBar = true;
        return;
      }
      if (!this.state) {
        this.msg = "Please Provide State.";
        this.showSnackBar = true;
        return;
      }
      if (!this.address) {
        this.msg = "Please Provide Address.";
        this.showSnackBar = true;
        return;
      }
      if (!this.category) {
        this.msg = "Please Select Category.";
        this.showSnackBar = true;
        return;
      }
      if (!this.mobileno) {
        this.msg = "Please Provide Mobile Number.";
        this.showSnackBar = true;
        return;
      }
      if (!phoneRegex.test(this.mobileno)) {
        this.msg = "Please Provide a Valid Mobile Number (10 digits).";
        this.showSnackBar = true;
        return;
      }
  
  if (!this.password) {
    this.msg = "Please Provide Password.";
    this.showSnackBar = true;
    return;
  }
  if (!this.cpassword) {
    this.msg = "Please Confirm Password.";
    this.showSnackBar = true;
    return;
  }
  if (this.password !== this.cpassword) {
    this.msg = "Password and Confirm Password must be the same.";
    this.showSnackBar = true;
    return;
  }
      
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/hospital/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          name: this.name,
          lat: this.lat,
          lon: this.lon,
          Districtname: this.district,
          State: this.state,
          address: this.address,
          mobileno: this.mobileno,
          category: this.category,
          password:this.password,
          notes: this.notes
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
              this.name = "";
              this.lat = "";
              this.lon = "";
              this.district = "";
              this.state = "";
              this.address = "";
              this.mobileno = "";
              this.mobileno = "";
              this.category = "";
              this.password = "";
              this.cpassword = "";
              this.notes = "";
              this.$router.push({ name: "Viewhospital" });
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
  },
};
</script>
    <style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: sofiaProRegular;
  font-size: 16px;
  font-weight: 500;
}
.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>